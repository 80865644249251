import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "redux/store";
// import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/main.css";
import "./assets/css/custom.css";
import "./assets/css/owlcss.css";
// import * as Sentry from "@sentry/react";
import Tracker from '@openreplay/tracker';


import Spinner from "components/spinners/Fallback-Spinner";
const LazyApp = lazy(() => import("App"));

/*
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [ /^https:\/\/admin\.virtualchanakya\.org\/api/],
        }),
        new Sentry.Replay({
            networkDetailAllowUrls: [/^https:\/\/admin\.virtualchanakya\.org\/api/],

        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event,hint) {
        console.log("event",event)
        return event;
    },
});
*/






ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <HelmetProvider>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        {/* <IntlProvider locale={language} messages={messages}> */}
        <LazyApp />
        <ToastContainer newestOnTop />

        {/* </IntlProvider> */}
      </Suspense>
    </Provider>
  </HelmetProvider>
);

//reportWebVitals(console.log);
